import React from 'react'
import RouterView from "./router/index"
import routerlist from "./router/list"
import {HashRouter as Router} from "react-router-dom"
import ApprootLeft from "./components/App/ApprootLeft"
import "./assets/css/index.css"
import "antd/dist/antd.css";
import Header from "./components/Header/Header"
class App extends React.Component{
  state={
    headertitle:"直播间管理>直播间列表"
  }
  render(){
    return <Router>
                  <div className="root-left">
                    <span style={{display:"block",height:"50px",width:"100%",fontSize:"28px",color:"white",fontFamily:"微软雅黑 Bold",fontWeight:"700",textAlign:"center"}}>LOGO</span>
                    <ApprootLeft changeheadertitle={(e)=>{this.setState({headertitle:e})}}/>
                  </div>
                  <div className="root-right">
                    <header className="root-right-header">
                        <Header headertitle={this.state.headertitle}/>
                    </header>
                    <main className="root-right-main">
                      <RouterView routerlist={routerlist} changeheadertitle={(e)=>{this.setState({headertitle:e})}}/>
                    </main>
                  </div>
             </Router>
              
  }
}

export default App