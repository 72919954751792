import axios from 'axios'
let instance = axios.create({
    headers: {
      'Content-Type':'application/octet-stream'
    }
  })
instance.interceptors.request.use(
config => {
    const token = sessionStorage.userinfo?JSON.parse(sessionStorage.userinfo).token:""
    if (token ) { // 判断是否存在token，如果存在的话，则每个http header都加上token
    config.headers.token = token  //请求头加上token
    }
    return config
},
err => {
    return Promise.reject(err)
})

instance.interceptors.response.use(
    response => {
      //拦截响应，做统一处理 
      return response
    },
    //接口错误状态处理，也就是说无响应时的处理
    error => {
      console.log(error)
      return Promise.reject(error) // 返回接口返回的错误信息
    })

    export default instance