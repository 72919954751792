import loadavle from "../util/loadable"
let Livestudio=loadavle(()=>import("../views/Livestudio/Livestudio"))
let Videolist=loadavle(()=>import("../views/Videolist/Videolist"))
let LivestreaminglistPage=loadavle(()=>import("../views/LivestreaminglistPage/LivestreaminglistPage"))
let Pushflow=loadavle(()=>import("../views/Pushflow/Pushflow"))
let Watch=loadavle(()=>import("../views/Watch/Watch"))
let LivestudioEdit=loadavle(()=>import("../views/LivestudioEdit/LivestudioEdit"))
let Chatroomlist=loadavle(()=>import("../views/Chatroomlist/Chatroomlist"))
let Loagin=loadavle(()=>import("../views/Loagin/Loagin"))
let Managementcontent=loadavle(()=>import("../views/Managementcontent/Managementcontent"))
let Chatpage=loadavle(()=>import("../views/Chatpage/Chatpage"))
let Broadcastinglist=loadavle(()=>import("../views/Broadcastinglist/Broadcastinglist"))
let routerlist=[
    {
        path:"/livestudio",//直播间列表页
        name:"Livestudio",
        components:Livestudio
    },
    {
        path:"/videolist",//视频列表页
        name:"Videolist",
        components:Videolist
    },
    {
        path:"/livestreaminglistPage",//直播流列表页
        name:"LivestreaminglistPage",
        components:LivestreaminglistPage
    },
    {
        path:"/pushflow",//推流页
        name:"Pushflow",
        components:Pushflow
    },
    {
        path:"/watch",//观看
        name:"Watch",
        components:Watch
    },
    {
        path:"/livestudioEdit",//编辑直播间页面
        name:"LivestudioEdit",
        components:LivestudioEdit
    },
    {
        path:"/chatroomlist",//聊天室列表
        name:"Chatroomlist",
        components:Chatroomlist
    },
    {
        path:"/managementcontent",//管理内容
        name:"Managementcontent",
        components:Managementcontent
    },
    {
        path:"/chatpage",//聊天页
        name:"Chatpage",
        components:Chatpage
    },
    {
        path:"/loagin",//登录
        name:"Loagin",
        components:Loagin
    },
    {
        path:"/broadcastinglist",//录播
        name:"Broadcastinglist",
        components:Broadcastinglist
    },
    {
        path:"/",
        redirect:"/loagin"
    }
]

export default routerlist