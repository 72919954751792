import React from "react"
import {Switch,Route,Redirect} from "react-router-dom"
function routerView(props){
    let {routerlist,changeheadertitle}=props;
    let comp=routerlist.filter(item=>item.components)
    let redi=routerlist.filter(item=>item.redirect)
    return<Switch>
            {
                comp.map((item,index)=><Route path={item.path} render={(props)=>{return <item.components props={props} changeheadertitle={changeheadertitle}></item.components>}} key={index}></Route>).concat(redi.map((item,index)=><Redirect from={item.path} to={item.redirect} key={index}></Redirect>))
            }
          </Switch>
}
export default routerView