import React, { Component } from 'react'
import { Menu } from 'antd';
import {
  AppstoreOutlined,
  MailOutlined,
} from '@ant-design/icons';
import {withRouter} from "react-router-dom"
const { SubMenu } = Menu;
 class ApprootLeft extends Component {
    state = {
        collapsed: false,
        defaultOpenKeys:['sub1']
    };
    toggleCollapsed = () => {
        this.setState({
          collapsed: !this.state.collapsed,
        });
    };
    render() {
        console.log(new Uint8Array())
        return (
            <div style={{ width: "100%" }}>
                <Menu
                defaultSelectedKeys={['2']}
                defaultOpenKeys={this.state.defaultOpenKeys}
                mode="inline"
                theme="dark"
                inlineCollapsed={this.state.collapsed}
                onOpenChange={(openKeys)=>{this.setState({defaultOpenKeys:openKeys})}}
                >
                    <SubMenu key="sub1" icon={<MailOutlined />} title="直播间管理">
                        <Menu.Item key="2"  onClick={()=>{
                            this.props.changeheadertitle("直播间管理>直播间列表")
                            this.props.history.push("/livestudio")
                        }}>直播间列表</Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub2" icon={<AppstoreOutlined />} title="直播管理">
                        <Menu.Item key="3" onClick={()=>{
                            this.props.changeheadertitle("直播流管理>视频列表")
                            this.props.history.push("/videolist")
                            }}>直播流列表</Menu.Item>
                            
                        <Menu.Item key="4" onClick={()=>{
                            this.props.changeheadertitle("直播流管理>录播列表")
                            this.props.history.push("/broadcastinglist")
                        }}>录播列表</Menu.Item>
                    </SubMenu>
                    <SubMenu key="sub4" icon={<MailOutlined />} title="聊天室管理">
                        {/* <Menu.Item key="7" onClick={()=>{
                            this.props.history.push("/chatroomlist")
                            this.props.changeheadertitle("聊天室管理>聊天室列表")
                            }}>聊天室列表</Menu.Item> */}
                        {/* <Menu.Item key="8" onClick={()=>{
                            this.props.history.push("/managementcontent")
                            this.props.changeheadertitle("聊天室管理>管理内容")
                            }}>管理内容</Menu.Item> */}
                            <Menu.Item key="9" onClick={()=>{
                            this.props.history.push("/chatpage")
                            this.props.changeheadertitle("聊天室管理>聊天室")
                            }}>聊天室</Menu.Item>
                    </SubMenu>
                </Menu>
            </div>
        )
    }
}


export default withRouter(ApprootLeft)