import React, { Component } from 'react'
import {withRouter} from "react-router-dom"
import container from "./HeaderContainer"
class Header extends Component {
    render() {
        return (
            <div className="Header">
                <li>{this.props.headertitle}</li>
        <li><span>{sessionStorage.userinfo?JSON.parse(sessionStorage.userinfo).name:""}</span><span onClick={()=>{
                     this.props.singout()
                    this.props.history.push("/loagin")
        }}>{sessionStorage.userinfo?"【退出】":"登陆"}</span></li>
            </div>
        )
    }
}
export default container(withRouter(Header))