import instance from "./intercept"

// let ap=""
// http://172.16.10.20:9080 超杰
//"http://172.16.10.88:8080/api"  权红
// let test="https://live.api.lejian.net/"//测试
let test="https://api.live.lejian.com/"//正式
// let cs="https://live.api.lejian.net"//测试src
let cs="https://api.live.lejian.com/"//正式src
export async function getLivestudiolist(obj){//获取直播间数据
    let src=obj.startTime?test+`hub/list?current=${obj.current}&size=${obj.size}&startTime=${obj.startTime}&endTime=${obj.endTime}`:test+`hub/list?current=${obj.current}&size=${obj.size}`
    let data=await instance.get(src)
    return data
}


export async function getLivestreaminglist(obj){//获取直播流列表
    let data=await instance.post(test+"stream/list",obj)
    return data
}



export async function getSeeLivestreaminglist(obj){//获取查看直播流列表
    let src=test+`video/list?current=${obj.current}&size=${obj.size}&streamId=${obj.streamId}`
    let data=await instance.get(src)
    return data
}



export async function getPushflowSrc(streamId){//获取推流地址
    let data=await instance.get(test+`stream/push?streamId=${streamId}`)
    return data
}


// export async function loaGin(obj){//登录接口    //权红登录
//     let prs=Qs.stringify(obj)
//     let data=await instance.get(`/user/login?${prs}`)
//     return data
// }

// export async function logins(obj){//登录     聊天登录  本地
//     let data=await instance.post(`/lecare_live/user/login?userName=${obj.userName}&password=${obj.password}`)
//     return data
// }

export async function logins(obj){//登录     聊天登录  测试
    let data=await instance.post(test+`user/login?userName=${obj.userName}&password=${obj.password}`)
    return data
}


export async function Signout(){//退出登录接口
    let data=await instance.get(test+`user/logout`)
    return data
}


export async function UpdataLivestudio(obj){//编辑直播间
    let data=await instance.post(test+`hub/update`,obj,{headers:{
        "content-type":"multipart/form-data"
    }})
    return data
}



export async function Generateplayback(obj){//生成回放
    let data=await instance.get(test+`video/save/playback?start=${obj.start}&end=${obj.end}&streamId=${obj.streamId}&format=${obj.format}&videoName=${obj.videoName}&videoId=${obj.videoId}`)
    return data
}


export async function Playbackoffshelf(obj){//回放下架
    let data=await instance.get(test+`video/closeShow?videoId=${obj.videoId}`)
    return data
}


export async function Playbackonshelf(obj){//回放上架
    let data=await instance.post(test+`video/show`,obj,{headers:{
        "content-type":"multipart/form-data"
    }})
    return data
}

export async function getHublist(obj){//回放上架
    let data=await instance.get(test+`hub/list`)
    return data
}


export async function Uploadfile(obj){//上传文件
    let data=await instance.post(test+`video/playBack/upload`,obj,{headers:{
        "content-type":"multipart/form-data"
    }})
    return data
}


export async function Recordedlist(obj){//录播列表
    let data=await instance.get(test+`video/recorded/list?current=${obj.current}&size=${obj.size}`)
    return data
}


// export async function getfriendlist(){//获取好友列表  本地
//     let data=await instance.get(`/lecare_live/chatroom/get_userinfo`)
//     return data
// }


// export async function getChatrecord(obj){//获取好友聊天记录  本地
//     let data=await instance.get(`/lecare_live/chatroom/chatLog/${obj.id}?current=${obj.current}`)
//     return data
// }


// export async function sendfile(obj){//聊天时发送文件接口  本地
//     let data=await instance.post(`/lecare_live/chatroom/upload`,obj)
//     return data
// }





export async function getfriendlist(){//获取好友列表  测试
    let data=await instance.get(cs+`/chatroom/get_userinfo`)
    return data
}


export async function getChatrecord(obj){//获取好友聊天记录  测试
    let data=await instance.get(cs+`/chatroom/chatLog/${obj.id}?current=${obj.current}`)
    return data
}


export async function sendfile(obj){//聊天时发送文件接口  测试
    let data=await instance.post(cs+`/chatroom/upload`,obj)
    return data
}