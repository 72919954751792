import React, { Component } from 'react'
import {Signout} from "../../api/index"
import {message} from "antd"
let defaultHeaderContainer=(Comp)=>{
    return class defaultHeaderContainer extends Component {
        render() {
            return <Comp singout={this.singout.bind(this)} headertitle={this.props.headertitle}></Comp>
        }
       async singout(){
           let data=await Signout()
           if(data.data.message==="退出成功!")
           {
            sessionStorage.setItem("userinfo","")
            message.success(data.data.message,5)
           }
       }
    }
}
 
export default defaultHeaderContainer